/**
 * This is the starting point of your application.
 * Feature Hub Apps will use this file to bootstrap the app.
 */
import React from 'react';
import { useContent } from '@oneaudi/feature-app-utils';
import StageContainer from './components/stage-container/StageContainer';
import { FalconContent, StagePropsFACE } from '../types';

import { transformCmsDataToProps } from './utils/transformCmsDataToProps';
import ContentContextProvider from './ContentContext';

const FeatureApp: React.FC = () => {
  const rawContent = useContent<StagePropsFACE | FalconContent>() || undefined;
  const content = transformCmsDataToProps(rawContent as StagePropsFACE | FalconContent);

  if (typeof content === 'undefined') {
    return <p>No content found for Stage Component</p>;
  }

  return (
    <ContentContextProvider content={content}>
      <StageContainer />
    </ContentContextProvider>
  );
};

export default FeatureApp;
