import {
  TechnicalDataConsumptionAndEmissionSummary,
  VehicleType,
  FuelType,
  CarByCodeForStageFaQuery,
  CarlineForStageFaQuery,
  ConfiguredCar,
  CarlineConsumptionAndEmissionSummary,
} from '../../../generated/onegraph';

export function extractConsumptionAndEmissionValues(
  consumptionAndEmissionSummary?: TechnicalDataConsumptionAndEmissionSummary | null,
  vehicleType?: VehicleType | null,
): CarlineConsumptionAndEmissionSummary | null {
  if (!consumptionAndEmissionSummary || !vehicleType) return null;

  let powerConsumption;
  let consumption;
  let emissionSummary;
  let fuelConsumptionBatteryDischarged = '';
  let classBatteryDischarged = '';

  const { eecsSummary, consumptionsSummary, emissionsCO2Summary } = consumptionAndEmissionSummary;
  if (isNotDefined(consumptionsSummary)) throw new Error('no consumptionSummary');
  if (isNotDefined(emissionsCO2Summary)) throw new Error('no emissionSummary');

  if (vehicleType === VehicleType.Bev) {
    const electricCons = consumptionsSummary?.find((con) => con.fuelType === FuelType.Electrical);
    powerConsumption = electricCons?.values?.[0]?.formattedValue;
  } else if (vehicleType === VehicleType.Icev) {
    const fuelCons = consumptionsSummary?.find(
      ({ fuelType }) => fuelType && isICEfuelType(fuelType),
    );
    consumption = fuelCons?.values?.[0]?.formattedValue || '';
  } else {
    const electricCons = consumptionsSummary?.find((con) => con.fuelType === FuelType.Electrical);
    powerConsumption = electricCons?.values?.[0]?.formattedValue || '';

    emissionSummary = emissionsCO2Summary?.find((con) => con.values !== null)?.values?.[0]
      ?.formattedValue;

    const fuelCons = consumptionsSummary?.find(
      ({ fuelType }) => fuelType && isICEfuelType(fuelType),
    );
    fuelConsumptionBatteryDischarged = fuelCons?.values?.[0]?.formattedValue || '';

    consumption = fuelCons?.values?.[1]?.formattedValue || '';
    classBatteryDischarged = eecsSummary?.[1]?.formattedValue || '';
  }

  const classFb = eecsSummary?.[0]?.formattedValue || '';
  if (!emissionSummary) {
    emissionSummary = emissionsCO2Summary?.[0]?.values?.[0]?.formattedValue as string;
  }

  return {
    consumption: `${consumption || ''}${consumption && powerConsumption ? '; ' : ''}${powerConsumption || ''}`,
    emissionCO2: emissionSummary,
    co2Class: classFb,
    dischargedConsumption: fuelConsumptionBatteryDischarged,
    dischargedCO2Class: classBatteryDischarged,
  };
}

const isNotDefined = <T>(input: T | undefined | null): input is undefined | null => {
  if (input === undefined) return true;
  if (input === null) return true;

  return false;
};

function isICEfuelType(fuelType: FuelType) {
  return Boolean(
    fuelType === FuelType.Petrol || fuelType === FuelType.Diesel || fuelType === FuelType.Cng,
  );
}
export function basePrNumbersToItems(basePrNumbers?: string) {
  return basePrNumbers ? basePrNumbers.split(',') : [];
}

export function isCarlineData(obj: unknown): obj is CarlineForStageFaQuery {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    obj &&
    typeof (obj as CarlineForStageFaQuery).carline === 'object' &&
    (obj as CarlineForStageFaQuery).carline !== null
  );
}

function isConfiguredCar(car: CarByCodeForStageFaQuery['carByCode']): car is ConfiguredCar {
  return car?.__typename === 'ConfiguredCar';
}

export function getcNeDataForCarByCode(query: CarByCodeForStageFaQuery) {
  if (!query) return null;

  const { carByCode } = query;
  const isConfigured = isConfiguredCar(carByCode);

  if (isConfigured) {
    const audicodeSummary = carByCode.technicalData?.consumptionAndEmission?.summary;
    const vehicleType = carByCode.carlineStructureCarline?.vehicleType;

    const cneData = extractConsumptionAndEmissionValues(audicodeSummary, vehicleType);
    return cneData;
  }

  console.log('carByCode is not a ConfiguredCar or is null');
  return null;
}
