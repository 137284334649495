import React, { useContext } from 'react';
import {
  audiDarkTheme,
  audiLightTheme,
  Button,
  ButtonGroup,
  ThemeProvider,
} from '@audi/audi-ui-react';
import styled from 'styled-components';
import type { Theme } from '@audi/audi-ui-react';
import { LayerContentHTML } from '@oneaudi/fa-one-layer/dist/utils/cjs';
import { FocusLayerSizeV2 } from '@volkswagen-onehub/layer-manager';
import { UeElement } from '@oneaudi/falcon-tools';
import { CtaButton, Mode, ContentPosition, CTAsTheme, Theme as AppTheme } from '../../../types';
import { setHeadlessUrl } from '../../utils/setHeadlessUrl';
import { ClickableElement } from './RichText';
import { Tracking } from '../../hooks/useTracking';
import { Context } from '../../Context';
import { ContentContext } from '../../ContentContext';

const ResponsiveButtonsWrapper = styled.div<{
  isAnchorCTA: boolean;
  mode: Mode;
  contentPosition: ContentPosition;
  isMobile?: boolean;
  backgroundDisclaimersCTAs: CTAsTheme;
  isBottomTextBlock?: boolean;
}>`
  ${({ isAnchorCTA }: { isAnchorCTA: boolean }) =>
    isAnchorCTA &&
    `
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
  `}
  padding: ${({
    theme,
  }: {
    theme: Theme;
  }) => `var(${theme.responsive?.spacing.l}) var(${theme.responsive?.spacing.pageMargin}) var(${theme.responsive?.spacing.xxl}) var(${theme.responsive?.spacing.pageMargin});
  `}
  z-index: 4;
  ${({
    theme,
    mode,
    contentPosition,
    isMobile,
    backgroundDisclaimersCTAs,
    isBottomTextBlock = false,
  }: {
    theme: Theme;
    mode: Mode;
    contentPosition: ContentPosition;
    isMobile?: boolean;
    backgroundDisclaimersCTAs?: CTAsTheme;
    isBottomTextBlock?: boolean;
  }) => {
    const isMobileAndLarge = isMobile && mode === Mode.LARGE;
    const bottomPaddingWithDisclaimers = isBottomTextBlock
      ? 0
      : `var(${theme.responsive?.spacing.xxl})`;
    const paddingTop = mode === Mode.LARGE ? 0 : `var(${theme.responsive?.spacing.l})`;
    const paddingBottom = isMobileAndLarge
      ? `var(${theme.responsive?.spacing.xxl})`
      : bottomPaddingWithDisclaimers;

    const backgroundColor = (() => {
      switch (backgroundDisclaimersCTAs) {
        case CTAsTheme.Light:
          return audiLightTheme.colors.background.level[0];
        case CTAsTheme.Dark:
          return audiDarkTheme.colors.background.level[0];
        case CTAsTheme.Media:
          return mode === Mode.SMALL && theme.colors.background.level[0];
        default:
          return audiLightTheme.colors.background.level[0];
      }
    })();

    if (contentPosition === ContentPosition.TOP_LEFT) {
      return `padding: ${paddingTop} var(${theme.responsive?.spacing.pageMargin}) ${paddingBottom} var(${theme.responsive?.spacing.pageMargin});`;
    }
    if (contentPosition === ContentPosition.BOTTOM_LEFT) {
      return `padding: ${paddingTop} var(${theme.responsive?.spacing.pageMargin}) ${paddingBottom} var(${theme.responsive?.spacing.pageMargin});`;
    }
    if (contentPosition === ContentPosition.CENTER) {
      return `
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: ${paddingTop};
        padding-bottom: ${paddingBottom};
        background-color: ${isMobile && mode === Mode.SMALL && backgroundColor};
      `;
    }
    if (contentPosition === ContentPosition.TOP_RIGHT) {
      return `padding-bottom: ${paddingBottom};`;
    }
    if (
      backgroundDisclaimersCTAs === CTAsTheme.Light ||
      backgroundDisclaimersCTAs === CTAsTheme.Media
    ) {
      return `backgroundColor: ${isMobile && mode === Mode.SMALL && backgroundColor};`;
    }
    return '';
  }}

  a,
  button {
    width: 100% !important;
  }
  // This hack modifies the button layout between 440px and 767px to stack horizontally, and stretch to full width up until the m breakpoint.
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.m}px) {
    a,
    button {
      width: auto !important;
    }
    position: static;
    left: auto;
    bottom: auto;
    right: auto;
    padding: 0;
  }
`;

interface StageButtonsProps {
  buttons?: CtaButton[];
  tracking: Tracking;
  isMobile?: boolean;
}

const StageButtons: React.FC<StageButtonsProps> = ({ buttons, tracking, isMobile = false }) => {
  const { content } = useContext(ContentContext);
  const { appearance, consumptionAndEmissions, disclaimers } = content;
  const { mode, contentPosition, backgroundDisclaimersCTAs } = appearance;
  // Checks if buttons have been added via the cms
  const hasHeadfullContent = buttons && buttons.length > 0;
  const hasHeadlessContent = buttons && buttons.some((b) => b.label);

  const isBottomTextBlock =
    consumptionAndEmissions &&
    consumptionAndEmissions.length > 0 &&
    disclaimers &&
    disclaimers.length > 0;

  if (!hasHeadfullContent || !hasHeadlessContent) return null;

  const isAnchorCTA =
    mode === Mode.LARGE &&
    (contentPosition === ContentPosition.TOP_LEFT || contentPosition === ContentPosition.TOP_RIGHT);

  const { layerManager } = useContext(Context);

  const handleOpenInLayer = React.useCallback((url: string) => {
    // eslint-disable-next-line react/destructuring-assignment
    layerManager?.openFocusLayer(
      () => <LayerContentHTML enableDeepLinking url={`${setHeadlessUrl(url)}`} />,
      {},
      {
        userCloseable: true,
        size: FocusLayerSizeV2.A,
      },
    );
  }, []);

  const Buttons = () => {
    return buttons?.map(({ label, url, ariaLabel, openInTabOrLayer }, index) => {
      if (index >= 2) {
        return null;
      }
      if (label && openInTabOrLayer === 'chatbot') {
        return (
          <Button
            key={`${label}-${String(index)}-chatbot-cta`}
            // link that has the nm-j-poa classname attached to it will open the chat bot upon clicking
            className="nm-j-poa"
            variant={index === 0 ? 'primary' : 'secondary'}
            aria-label={ariaLabel}
            onClick={() =>
              tracking.sendClickEvent({
                url, // URL of the clicked element
                label, // 	Link text of the clicked element
                index,
                openInTabOrLayer,
              })
            }
          >
            <UeElement type="text" property={`${getButton(index)}Label`} label="Label">
              {label}
            </UeElement>
          </Button>
        );
      }
      if (label && url) {
        return (
          <Button
            key={`${label}-${String(index)}-cta`}
            variant={index === 0 ? 'primary' : 'secondary'}
            aria-label={ariaLabel}
            newWindow={openInTabOrLayer === 'tab'}
            href={openInTabOrLayer === 'layer' ? undefined : url}
            onClick={() => {
              if (openInTabOrLayer === 'layer') {
                handleOpenInLayer(url);
              }
              tracking.sendClickEvent({
                url, // URL of the clicked element
                label, // 	Link text of the clicked element
                index,
                openInTabOrLayer,
              });
            }}
          >
            <UeElement type="text" property={`${getButton(index)}Label`} label="Label">
              {label}
            </UeElement>
          </Button>
        );
      }
      return null;
    });
  };

  const buttonsTheme = () => {
    if (backgroundDisclaimersCTAs !== CTAsTheme.Media) {
      if (isMobile && mode === Mode.SMALL) {
        return backgroundDisclaimersCTAs === CTAsTheme.Light ? audiLightTheme : audiDarkTheme;
      }
      if (!isMobile) {
        return appearance.theme === AppTheme.Light ? audiLightTheme : audiDarkTheme;
      }
      return backgroundDisclaimersCTAs === CTAsTheme.Light ? audiDarkTheme : audiLightTheme;
    }
    return appearance.theme === AppTheme.Light ? audiLightTheme : audiDarkTheme;
  };

  return (
    <ThemeProvider theme={buttonsTheme()}>
      <ResponsiveButtonsWrapper
        isAnchorCTA={isAnchorCTA}
        contentPosition={contentPosition}
        mode={mode}
        isMobile={isMobile}
        backgroundDisclaimersCTAs={backgroundDisclaimersCTAs}
        isBottomTextBlock={isBottomTextBlock}
      >
        <ClickableElement>
          <ButtonGroup
            variant="block-buttons"
            spaceStackStart="l"
            spaceStackEnd="xxs"
            data-testid={`${isMobile ? 'mobile' : 'desktop'}-responsive-button-group`}
            block={isMobile}
          >
            <Buttons />
          </ButtonGroup>
        </ClickableElement>
      </ResponsiveButtonsWrapper>
    </ThemeProvider>
  );
};

export default StageButtons;

function getButton(index: number): string {
  if (index === 0) {
    return 'firstButton';
  }
  if (index === 1) {
    return 'secondButton';
  }
  throw new Error('Invalid index. Valid indices are 0 and 1');
}
