import { TrackingServiceV2 } from '@oneaudi/audi-tracking-service';
import { OpenInTabOrLayer } from '../../types';
import { setHeadlessUrl } from './setHeadlessUrl';

type EventAction = 'content' | 'exit_link' | 'internal_link' | '';
export const setEventAction = (
  trackingService: TrackingServiceV2,
  displayMode: OpenInTabOrLayer,
  link: string,
): EventAction => {
  let action: EventAction = '';

  if (displayMode === 'chatbot') {
    action = 'content';
  } else if (displayMode === 'page' || displayMode === 'tab') {
    action = (trackingService?.evaluateLinkType(link) as EventAction) || '';
  } else if (displayMode === 'layer') {
    action = 'internal_link';
  }
  return action;
};

const completeTargetURL = (targetURL: string): string => {
  const { host } = document.location;
  if (targetURL.startsWith('/')) {
    return `${host}${targetURL}`;
  }
  return targetURL;
};

export const setTargetURL = (url: string, openInTabOrLayer: OpenInTabOrLayer) => {
  if (openInTabOrLayer === 'layer') {
    return completeTargetURL(`${setHeadlessUrl(url)}`);
  }
  if (openInTabOrLayer === 'chatbot') {
    // always empty if chatbot is selected }
    return '';
  }
  return completeTargetURL(url);
};

export const removeSpecialCharacters = (text: string | undefined) => {
  if (!text) {
    return '';
  }
  return text
    .replace(/&#8203;/gi, '')
    .replace(/&zwnj;/gi, '')
    .replace(/&nbsp;/gi, '');
};
