/**
 * Throttles the Scroll function by delaying subsequent executions.
 *
 * @param func - The function to throttle.
 * @param delay - The number of milliseconds to delay the function call.
 * @returns A new, throttled version of the passed function.
 */

export const throttleScroll = (
  func: (scrollPos: number) => void,
  delay: number,
): ((scrollPos: number) => void) => {
  let lastCall = 0;
  return (scrollPos: number) => {
    const now = new Date().getTime();
    if (now - lastCall < delay) {
      return;
    }
    lastCall = now;
    // eslint-disable-next-line consistent-return
    return func(scrollPos);
  };
};
