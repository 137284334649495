import React, { createContext, PropsWithChildren, useMemo } from 'react';

import { StageProps } from '../types';

export interface ContextState {
  readonly content: StageProps;
}

export const ContentContext = createContext<ContextState>({} as ContextState);

export interface ContentContextProviderProps {
  readonly content: StageProps;
}

const ContentContextProvider = ({
  children,
  content,
}: PropsWithChildren<ContentContextProviderProps>) => {
  const value = useMemo(
    () => ({
      content,
    }),
    [content],
  );

  return <ContentContext.Provider value={value}>{children}</ContentContext.Provider>;
};
export default ContentContextProvider;
