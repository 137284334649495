import { useContext } from 'react';
import { Context } from '../Context';
import { isTrackingDisabled, debug } from '../utils/debugging';
import { StageContainerContent } from '../components/stage-container/StageContainer';
import { OpenInTabOrLayer } from '../../types';
import { removeSpecialCharacters, setEventAction, setTargetURL } from '../utils/trackingUtils';

export interface Tracking {
  sendReadyEvent(): void;
  registerImpressionTracking(): void;
  sendClickEvent(clickTrackingAttributes: ClickTrackingAttributes): void;
}

interface ClickTrackingAttributes {
  url: string; // URL of the clicked element
  label: string; // 	Link text of the clicked element
  index: number;
  openInTabOrLayer: OpenInTabOrLayer;
}

export function useTracking(
  ref: React.MutableRefObject<null>,
  content: StageContainerContent,
  attributes?: {
    version?: string;
    implementer?: number;
    [key: string]: unknown;
  },
): Tracking {
  if (isTrackingDisabled()) {
    debug('TRACKING DISABLED');

    return {
      sendReadyEvent: () => {
        debug('TRACKING not executed: sendReadyEvent');
      },
      registerImpressionTracking: () => {
        debug('TRACKING not executed: registerImpressionTracking');
      },
      sendClickEvent: (clickTrackingAttributes: ClickTrackingAttributes) => {
        debug('TRACKING not executed: sendClickEvent', clickTrackingAttributes);
      },
    };
  }

  const { trackingService } = useContext(Context);
  const { asset, text, buttons } = content;

  const featureAppName = 'editorial-stage';
  const componentNameWithDashes = featureAppName.replace('fa-', '');
  const componentNameWithSpaces = componentNameWithDashes.replace(/-/g, ' ');
  const defaultComponentAdditions = {
    componentInfo: {
      // not accepted by tracking lib
      // componentID: 'bla',
    },
    attributes: {
      version: __FEATURE_APP_VERSION__,
      ...attributes,
    },
  };

  return {
    sendReadyEvent: () => {
      if (trackingService) {
        const readyEvent = {
          event: {
            attributes: {
              componentName: componentNameWithDashes,
              elementName: '',
              label: '',
              clickID: '',
              pos: '',
              targetURL: '',
              value: '',
            },
            eventInfo: {
              eventAction: 'feature_app_ready',
              eventName: `${componentNameWithSpaces} - feature app ready`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        };
        trackingService.track(readyEvent);
      }
    },
    registerImpressionTracking: () => {
      if (trackingService && ref.current) {
        trackingService.registerImpressionTracking(ref.current, () => {
          return {
            event: {
              attributes: {
                componentName: componentNameWithDashes,
                elementName: '',
                label: '',
                clickID: '',
                pos: asset.assetType,
                targetURL: '',
                value: text.headline || '',
                relatedElements:
                  buttons.map((button, index) => {
                    return {
                      elementName: index === 0 ? 'primary cta' : 'secondary cta',
                      elementValue: `${`${index + 1}-${button.label}`}`,
                    };
                  }) || '',
              },
              eventInfo: {
                eventAction: 'impression',
                eventName: `${componentNameWithSpaces} - impression`,
              },
            },
            componentUpdate: defaultComponentAdditions,
          };
        });
      }
    },
    sendClickEvent: (clickTrackingAttributes) => {
      const { openInTabOrLayer, url, label, index } = clickTrackingAttributes;
      if (trackingService) {
        debug('Tracking: sendClickEvent', clickTrackingAttributes);
        trackingService.track({
          event: {
            attributes: {
              label, // 	Link text of the clicked element
              targetURL: setTargetURL(url, openInTabOrLayer),
              relatedElements: [
                {
                  elementName: index === 0 ? 'primary cta' : 'secondary cta',
                  elementValue: `${`${index + 1}-${label}`}`,
                },
              ], // related elements contains the ctas used in the component
              componentName: 'editorial-stage',
              elementName: 'button',
              clickID: '', // fixed value
              pos: '', // fixed value
              value: removeSpecialCharacters(text.headline),
            },
            eventInfo: {
              eventAction: setEventAction(trackingService, openInTabOrLayer, url),
              eventName: `editorial stage - click on cta${openInTabOrLayer === 'layer' ? ' - layer open' : ''}`,
            },
          },
          componentUpdate: defaultComponentAdditions,
        });
      }
    },
  };
}
