import { useEffect, useState } from 'react';
import { audiLightTheme } from '@audi/audi-ui-react';

export const useWindowSize = (): {
  height: number;
  width: number;
} => {
  const { s, m } = audiLightTheme.breakpoints;

  const [state, setState] = useState({
    height: typeof window !== 'undefined' ? window.innerHeight : m,
    width: typeof window !== 'undefined' ? window.innerWidth : s,
  });

  useEffect(() => {
    function handleResize(): void {
      setState({
        height: typeof window !== 'undefined' ? window.innerHeight : m,
        width: typeof window !== 'undefined' ? window.innerWidth : s,
      });
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('resize', handleResize);
      return (): void => {
        window.removeEventListener('resize', handleResize);
      };
    }

    return (): void => {};
  }, []);

  return state;
};
