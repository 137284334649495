import React from 'react';
import { Theme } from '@audi/audi-ui-react';
import styled from 'styled-components';
import { UeElement } from '@oneaudi/falcon-tools';
import {
  AppearanceProps,
  ContentPosition,
  CtaButton,
  CTAsTheme,
  TexContentProps,
} from '../../../types';
import StageButtons from './StageButtons';
import RichText from './RichText';
import { Tracking } from '../../hooks/useTracking';
import StageLegal from '../stage-legal/StageLegal';
import { OnegraphDataType } from '../../hooks/useDataFromOneGraph';

const StyledContainer = styled.div`
  width: inherit;
`;

interface StageContentWrapProps {
  appearance?: AppearanceProps;
}

const StageContentWrap = styled.div<StageContentWrapProps>`
  width: auto;
  overflow: hidden;
  max-width: 1920px;
  padding: 0 4%;
  padding: ${({ theme }: { theme: Theme }) => {
    return `0 var(${theme.responsive?.spacing.pageMargin}) var(${theme.responsive?.spacing.l}) var(${theme.responsive?.spacing.pageMargin});`;
  }};
  position: relative;
  z-index: 4 !important;
  white-space: pre-wrap;
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.m}px) {
    text-align: ${({ appearance }) =>
      appearance?.contentPosition === ContentPosition.CENTER && 'center;'};
  }
`;

interface StageContentProps {
  text?: TexContentProps;
  buttons?: CtaButton[];
  appearance: AppearanceProps;
  tracking: Tracking;
  isMobile?: boolean;
  oneGraphData?: OnegraphDataType;
  disclaimers?: string[];
}

const StageContent: React.FC<StageContentProps> = ({
  text,
  buttons,
  appearance,
  tracking,
  isMobile,
  oneGraphData,
  disclaimers,
}) => {
  const { headline, headingTag, subline } = text || {};
  const isLegalBottomLeft =
    appearance.backgroundDisclaimersCTAs === CTAsTheme.Media &&
    !isMobile &&
    appearance.contentPosition === ContentPosition.BOTTOM_LEFT;

  return (
    <StyledContainer>
      {/* delete padding bottom if mode large and bottom left */}
      <StageContentWrap data-testid="headline-subline-buttons-data" appearance={appearance}>
        {headline && (
          <UeElement type="text" property="textHeadline" label="Headline">
            {(ueProps) => (
              <RichText
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                as={headingTag || 'h1'}
                spaceStackEnd="xs"
                spaceStackStart="xl"
                variant="order1"
                text={headline}
                {...ueProps}
              />
            )}
          </UeElement>
        )}
        {subline && (
          <UeElement type="text" property="textSubline" label="Subline">
            {(ueProps) => (
              <RichText
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-expect-error
                as="span"
                spaceStackEnd="l"
                variant="order4"
                text={subline}
                {...ueProps}
              />
            )}
          </UeElement>
        )}
        {!isMobile && <StageButtons buttons={buttons} tracking={tracking} />}
      </StageContentWrap>
      {isMobile && <StageButtons buttons={buttons} tracking={tracking} isMobile={isMobile} />}
      {isLegalBottomLeft && (
        <StageLegal oneGraphData={oneGraphData} disclaimers={disclaimers} appearance={appearance} />
      )}
    </StyledContainer>
  );
};

export default StageContent;
