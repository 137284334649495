import { gql } from '@oneaudi/onegraph-service';

export const CAR_BY_CODE_QUERY = gql`
  query CarByCodeForStageFA($code: String!) {
    carByCode(code: $code) {
      ... on ConfiguredCar {
        technicalData {
          consumptionAndEmission {
            summary {
              consumptionsSummary {
                values {
                  formattedValue
                }
                fuelType
                fuelName
              }
              eecsSummary {
                formattedValue
              }
              emissionsCO2Summary {
                values {
                  formattedValue
                }
              }
            }
          }
        }
        carlineStructureCarline {
          vehicleType
          fuelTypes
        }
      }
    }
  }
`;
