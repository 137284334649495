import * as React from 'react';
import styled from 'styled-components';
import { audiLightTheme, Theme as AudiTheme } from '@audi/audi-ui-react';
import { AppearanceProps, AssetTypeEnum, ContentPosition, Mode, Theme } from '../../../types';

const StageContentContainer = styled.div<{ m: number; contentPosition: ContentPosition }>`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: ${({ contentPosition }) => {
    if (
      contentPosition === ContentPosition.TOP_LEFT ||
      contentPosition === ContentPosition.TOP_RIGHT
    ) {
      return 'flex-start';
    }
    if (contentPosition === ContentPosition.BOTTOM_LEFT) {
      return 'flex-end';
    }
    return '';
  }};

  @media (min-width: ${({ theme }: { theme: AudiTheme }) => theme.breakpoints?.m}px) {
    // Styles for mobile devices
    align-items: ${({ contentPosition }) => {
      if (contentPosition === ContentPosition.CENTER) {
        return 'center';
      }
      return '';
    }};
    justify-content: ${({ contentPosition }) => {
      if (contentPosition === ContentPosition.CENTER) {
        return 'center';
      }
      return '';
    }};
  }
  @media (max-width: ${({ theme }: { theme: AudiTheme }) => theme.breakpoints?.m}px) {
    // Styles for mobile devices
    align-items: ${({ contentPosition }) => {
      if (contentPosition === ContentPosition.CENTER) {
        return 'flex-end';
      }
      return '';
    }};
    justify-content: ${({ contentPosition }) => {
      if (contentPosition === ContentPosition.CENTER) {
        return '';
      }
      return '';
    }};
  }
`;

interface ShaderProps {
  background: string;
  enableShader: boolean;
  contentPosition?: ContentPosition;
}

const ShaderBase = styled.div<ShaderProps>`
  pointer-events: none; // Make the container click-through
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  text-align: ${({ contentPosition }: { contentPosition?: ContentPosition }) => {
    return contentPosition === ContentPosition.CENTER ? 'center;' : 'left;';
  }};
`;

const ShaderTop = styled(ShaderBase)`
  background: ${({ background = 'rgba(0,0,0,.7)', enableShader }): string =>
    enableShader ? background : 'none'};
`;

const ShaderBottom = styled(ShaderBase)`
  background: ${({ background = 'rgba(0,0,0,1)', enableShader }): string =>
    enableShader ? background : 'none'};
`;

export interface StageShaderProps {
  appearance: AppearanceProps;
  assetType: AssetTypeEnum;
  children: React.ReactNode;
}

const HorizontalAlignmentDiv = styled.div<{ contentPosition?: ContentPosition }>`
  @media (min-width: ${({ theme }: { theme: AudiTheme }) => theme.breakpoints?.m}px) {
    ${({ contentPosition }: { contentPosition?: ContentPosition }) =>
      contentPosition === ContentPosition.TOP_RIGHT &&
      `
      position: relative;
      width: 100%;
      text-align: right;
  `}
  }
`;

const StageShader: React.FC<StageShaderProps> = (props: StageShaderProps) => {
  const { appearance, assetType, children } = props;
  const { theme, enableShader, contentPosition = ContentPosition.TOP_LEFT, mode } = appearance;
  const _enableShader = enableShader && assetType !== AssetTypeEnum.Video && theme === Theme.Dark;

  const { m } = audiLightTheme.breakpoints;

  const SHADER_CONFIG = {
    SMALL_MODE: {
      top: 'linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 50%)',
      bottom: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 47.9%, rgba(0, 0, 0, 0.60) 100%)',
    },
    LARGE_MODE: {
      top: 'linear-gradient(180deg, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0.00) 51.5%);',
      bottom: 'linear-gradient(180deg, rgba(0, 0, 0, 0.00) 46.66%, #000 100%)',
    },
  } as const; // Properties of the SHADER_CONFIG object cannot be changed

  const shaderBackground =
    mode === Mode.LARGE ? SHADER_CONFIG.LARGE_MODE : SHADER_CONFIG.SMALL_MODE;

  // Render
  if (
    contentPosition === ContentPosition.TOP_LEFT ||
    contentPosition === ContentPosition.TOP_RIGHT
  ) {
    return (
      <ShaderTop
        background={shaderBackground.top}
        enableShader={_enableShader}
        contentPosition={contentPosition}
      >
        <StageContentContainer contentPosition={contentPosition} m={m}>
          <HorizontalAlignmentDiv
            contentPosition={contentPosition}
            data-testid="responsive-horizontal-alignment"
          >
            {children}
          </HorizontalAlignmentDiv>
        </StageContentContainer>
      </ShaderTop>
    );
  }
  // No support for ContentPosition.BOTTOM_RIGHT in the bottom shader
  return (
    <ShaderBottom background={shaderBackground.bottom} enableShader={_enableShader}>
      <StageContentContainer contentPosition={contentPosition} m={m}>
        {children}
      </StageContentContainer>
    </ShaderBottom>
  );
};

export default StageShader;
