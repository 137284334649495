import React from 'react';
import styled from 'styled-components';
import { audiLightTheme, Theme } from '@audi/audi-ui-react';
import { UeContainer, UeElement, UeReference } from '@oneaudi/falcon-tools';
import { parseAssetValue } from '../../utils/parseAssetValue';
import { AssetTypeImage, Mode } from '../../../types';
import { useWindowSize } from '../../hooks';

export const ResponsiveMediaItemBase = styled.div<{
  mode: Mode;
  preserveVideoWidth?: boolean;
}>`
  height: ${({ mode }) => (mode === Mode.LARGE ? '100vh' : '100%')};
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.xs}px) {
    min-height: ${({ mode }) => (mode === Mode.LARGE ? '640px' : 'auto')};
    height: ${({ mode }) => (mode === Mode.SMALL ? '640px' : '100vh')};
  }
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.s}px) {
    min-height: ${({ mode }) => (mode === Mode.LARGE ? '640px' : 'auto')};
    height: ${({ mode }) => (mode === Mode.SMALL ? '640px' : '100vh')};
  }
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.m}px) {
    min-height: ${({ mode }) => (mode === Mode.LARGE ? '800px' : 'auto')};
    height: ${({ mode }) => (mode === Mode.SMALL ? '800px' : '100vh')};
  }
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.l}px) {
    min-height: ${({ mode }) => (mode === Mode.LARGE ? '640px' : 'auto')};
    height: ${({ mode }) => (mode === Mode.SMALL ? '640px' : '100vh')};
  }
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.xl}px) {
    min-height: ${({ mode }) => (mode === Mode.LARGE ? '640px' : 'auto')};
    height: ${({ mode }) => (mode === Mode.SMALL ? '640px' : '100vh')};
  }
  @media (min-width: ${({ theme }: { theme: Theme }) => theme.breakpoints?.xxl}px) {
    min-height: ${({ mode }) => (mode === Mode.LARGE ? '800px' : 'auto')};
    height: ${({ mode }) => (mode === Mode.SMALL ? '800px' : '100vh')};
  }
`;

const MediaItem = styled(ResponsiveMediaItemBase)`
  width: auto;
  object-fit: cover;
  position: relative;
`;

const Image = styled.img`
  height: 100%;
  object-fit: cover;
  position: relative;
  width: 100%;
`;

interface StageImageProps {
  asset: AssetTypeImage;
  mode: Mode;
}

const StageImage = ({ asset, mode }: StageImageProps) => {
  const { alt, portrait, landscape } = asset;
  const { width } = useWindowSize();
  const imagePortrait = parseAssetValue(portrait?.assetValue ?? '').src;
  const imageLandscape = parseAssetValue(landscape?.assetValue ?? '').src;

  const { breakpoints } = audiLightTheme;
  const { l, m, xl, xxl } = breakpoints;
  const isMobile = width < m;

  return (
    <UeContainer propertyPath="asset" label="images">
      <MediaItem mode={mode} data-test-id="fa-editorial-stage-image" className="mediaItem">
        <UeReference propertyPath="asset" behavior="component">
          {(ueProps) => (
            <picture {...ueProps}>
              {/* < 768 */}
              <UeElement type="media" property="assetPortrait" label="imagePortrait">
                {(ueElemProps) => (
                  <source
                    media={`(max-width: ${m}px)`}
                    srcSet={`${imagePortrait}?width=${m}`}
                    {...ueElemProps}
                  />
                )}
              </UeElement>
              {/* < 1024 */}
              <UeElement type="media" property="assetLandscape" label="imageLandscape">
                {(ueElemProps) => (
                  <source
                    media={`(max-width: ${l}px)`}
                    srcSet={`${imageLandscape}?width=${l}`}
                    {...ueElemProps}
                  />
                )}
              </UeElement>
              {/* < 1440 */}
              <UeElement type="media" property="assetLandscape" label="imageLandscape">
                {(ueElemProps) => (
                  <source
                    media={`(max-width: ${xl}px)`}
                    srcSet={`${imageLandscape}?width=${xl}`}
                    {...ueElemProps}
                  />
                )}
              </UeElement>
              {/* >= 1440 */}
              <UeElement type="media" property="assetLandscape" label="imageLandscape">
                {(ueElemProps) => (
                  <source
                    media={`(min-width: ${xl}px)`}
                    srcSet={`${imageLandscape}?width=${xxl}`}
                    {...ueElemProps}
                  />
                )}
              </UeElement>
              {/* fallback */}
              <UeElement
                type="media"
                property={isMobile ? 'assetPortrait' : 'assetLandscape'}
                label={isMobile ? 'imagePortrait' : 'imageLandscape'}
              >
                {(ueElemProps) => (
                  <Image
                    alt={alt}
                    data-object-fit
                    src={imagePortrait}
                    loading="lazy"
                    {...ueElemProps}
                  />
                )}
              </UeElement>
            </picture>
          )}
        </UeReference>
      </MediaItem>
    </UeContainer>
  );
};

export default StageImage;
