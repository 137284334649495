import { gql } from '@oneaudi/onegraph-service';

export const CARLINE_QUERY = gql`
  query CarlineForStageFA($identifier: CarlineIdentifierInput!) {
    carline(identifier: $identifier) {
      name
      prices {
        minPrice {
          value
        }
        monthlyRate {
          ... on MonthlyRate {
            rawValue
            valueAsText
            formattedValue
          }
        }
      }
      consumptionAndEmission {
        summary {
          consumption
          emissionCO2
          dischargedCO2Class
          dischargedConsumption
          co2Class
        }
      }
    }
  }
`;
